<template>
  <v-container fluid>
    <v-row class="login-container text-center full-height">
      <v-col class="login-container-card" cols="12">
        <v-col class="login-card">
          <div class="branding-container">
            <div class="branding">
              <img class="login-logo" src="@/assets/images/ph-logo-horizontal.svg" />
              <p class="login-system-title">Unified Service Portal</p>
            </div>
          </div>
          <br>
          <div class="ph-field-container">
            <div class="p-col-12 p-d-flex p-flex-column p-ai-center p-jc-center">
              <div class="login-page-icon-container">
                <img class="login-page-icon" v-bind:src="pageIconURL" />
              </div>
              <h1 class="reset-password-title">{{pageHeader}}</h1>
              <p class="reset-password-subtitle">{{pageSubheader}}</p>
              <!-- Main Form -->
              <v-form ref="changePassword">
                <v-col fluid cols="12" class="ph-textbox-container" v-if="showForm">
                  <v-text-field
                    autocomplete
                    placeholder="New Password"
                    hide-details
                    :append-icon="(revealNewPassword === true) ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    class="ph-textbox ph-textbox-new-password"
                    :error="(!hasNewPW && hasProcessUpdate) ? true : false"
                    v-model="newPW"
                    @click:append="revealNewPassword = !revealNewPassword"
                    :type="revealNewPassword ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
                <v-col fluid cols="12" class="ph-textbox-container" v-if="showForm">
                  <v-text-field
                    autocomplete
                    placeholder="Confirm New Password"
                    hide-details
                    :append-icon="(revealConfirmPassword === true) ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    class="ph-textbox ph-textbox-confirm-password"
                    :error="(!hasConfirmNewPW && hasProcessUpdate) ? true : false"
                    v-model="confirmNewPW"
                    @click:append="revealConfirmPassword = !revealConfirmPassword"
                    :type="revealConfirmPassword ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
                <v-col fluid cols="12" class="ph-action-buttons" v-if="showForm">
                  <v-col fluid cols="12" class="ph-action-button">
                    <v-btn x-large color="#1A2791" elevation="0" class="ph-login-button ph-login-reset-btn ph-login-update-password-btn" @click="openOTP">
                      Update Password
                    </v-btn>
                  </v-col>
                  <v-col fluid cols="12" class="ph-back-button-container">
                    <router-link to="/login" class="ph-back-button">
                      <img class="ph-back-logo" src="@/assets/images/icons/caret-back.svg" />
                      <p class="ph-back-text">Back to Login</p>
                    </router-link>
                  </v-col>
                </v-col>
              </v-form>
              <div v-if="showOTP">
                <v-col fluid cols="12" class="ph-textbox-container ph-otp-input-container">
                  <v-text-field
                    v-for="index in 6"
                    v-model="otp_code[index - 1]"
                    :error="(!hasOTPCode && hasProcessOTP) ? true : false"
                    :key="index"
                    :ref="'otpTxt' + index"
                    @paste="getCode($event,index)"
                    @keyup="tabSwitch(index)"
                    @keyup.enter="verifyOTP"
                    type="text"
                    placeholder="-"
                    hide-details
                    outlined
                    maxlength="1"
                    class="ph-textbox ph-otp-textbox">
                  </v-text-field>
                </v-col>
                <v-col fluid cols="12" class="ph-action-buttons">
                  <v-col fluid cols="12" class="ph-action-button">
                    <v-btn ref="verifyBtn" @click="verifyOTP" x-large color="#1A2791" elevation="0" class="ph-login-button ph-login-reset-btn ph-login-otp-btn">
                      Verify OTP
                    </v-btn>
                  </v-col>
                  <v-col fluid cols="12" class="ph-back-button-container">
                    <router-link @click.native="backToResetPassword" to="/reset-password" class="ph-back-button">
                      <img class="ph-back-logo" src="@/assets/images/icons/caret-back.svg" />
                      <p class="ph-back-text">Back to Reset Password</p>
                    </router-link>
                  </v-col>
                </v-col>
              </div>

              <!-- Callback Form -->
              <div v-if="showCallback">
                <v-col fluid cols="12" class="ph-action-buttons">
                  <div class="p-col-12 ph-action-button">
                    <v-btn x-large color="#1A2791" elevation="0" class="ph-login-button ph-login-reset-btn" @click="backToLoginPage">
                      Continue to Log in
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Buffer } from 'buffer'
import { format } from 'date-fns'
import { renderToast } from '@/utils'

export default {
  name: 'ResetPassword',
  data () {
    return {
      floatValue: null,
      currentPW: '',
      newPW: '',
      confirmNewPW: '',
      hasCurrentPW: '',
      hasNewPW: '',
      hasConfirmNewPW: '',
      hasProcessUpdate: false,
      showForm: true,
      showCallback: false,
      pageIconURL: '',
      pageHeader: '',
      pageSubheader: '',
      revealNewPassword: false,
      revealConfirmPassword: false,
      email: '',
      userCode: '',
      pin: '',
      getYear: format(new Date(), 'yyyy'),
      otp_code: ['', '', '', '', '', ''],
      hasOTPCode: '',
      hasProcessOTP: false,
      showOTP: false
    }
  },
  watch: {
    showCallback: {
      handler () {
        this.displayMessage()
      },
      deep: true
    },
    showOTP: {
      handler () {
        this.displayMessage()
      },
      deep: true
    }
  },
  created () {
    const queryString = window.location.search
    const getData = queryString.substring(1)
    const currentUser = Buffer.from(getData, 'base64')
    const currentUserString = currentUser.toString('ASCII')
    const currentUserData = JSON.parse(currentUserString)

    const emailAddress = currentUserData.email_address
    const userCode = currentUserData.user_code
    const otpPin = currentUserData.otp_pin
    this.email = emailAddress
    this.userCode = userCode
    this.pin = otpPin

    if (emailAddress === '' || emailAddress === null || emailAddress === undefined ||
      userCode === '' || userCode === null || userCode === undefined ||
      otpPin === '' || otpPin === null || otpPin === undefined) {
      renderToast('error', 'Error', 'You must request for a change password first.')
      this.$router.push('/login')
    }

    this.displayMessage()
  },
  methods: {
    renderToast,
    backToLoginPage () {
      location.href = '/login'
    },
    displayMessage () {
      if (this.showCallback) {
        this.pageIconURL = require('@/assets/images/icons/check-icon.svg')
        this.pageHeader = 'Password reset'
        this.pageSubheader = 'Your password has been successfully reset. Click continue below to log in.'
      } else if (this.showOTP) {
        this.pageIconURL = require('@/assets/images/icons/lock-icon.svg')
        this.pageHeader = 'Two-factor Authentication'
        this.pageSubheader = 'Input your 6-digit One-time password PIN from your authenticator app.'
      } else {
        this.pageIconURL = require('@/assets/images/icons/keypass-icon.svg')
        this.pageHeader = 'Forgot password'
        this.pageSubheader = 'No worries, we\'ll send you reset instructions. '
      }
    },
    validateInput (e) {
      e.data = e.data.replace(/[^0-9]/g, '')
    },
    tabSwitch (val) {
      const element = document.querySelectorAll('input')
      console.log(val)

      if (element[val - 1].value !== '' && val !== 6) {
        element[val].focus()
      } else if (element[val - 1].value === '' && val !== 1) {
        element[val - 2].focus()
      }
    },
    getCode (e, index) {
      let clipboardValue = ''
      let pastedValue = ''

      e.stopPropagation()
      e.preventDefault()

      // Get pasted data via clipboard API
      clipboardValue = e.clipboardData || window.clipboardData
      pastedValue = clipboardValue.getData('Text')

      const pastedValueToArray = pastedValue.split('')

      this.otp_code = pastedValueToArray

      this.$refs.verifyBtn.$el.focus()
    },
    verifyOTP () {
      const otpCodeArray = this.otp_code
      const otpCodeString = otpCodeArray.join('')

      this.hasProcessOTP = true

      const payload = {
        user_name: this.userCode,
        otp_code: otpCodeString,
        pin: this.pin,
        module: 'reset'
      }

      if (!this.otp_code || (this.otp_code.length < 6)) {
        renderToast('error', 'Invalid Code', 'The OTP Code is Invalid. The code must be 6-digits.')
      } else {
        this.$store.commit('SET_LOADING', true)
        // ajax call
        this.$store.dispatch('verifyOTP', payload).then(res => {
          const response = res.result

          this.$store.commit('SET_LOADING', false)

          if (res?.result && response !== false) {
            this.requestUpdatePassword()
          } else {
            this.otp_code = ['', '', '', '', '', '']
            renderToast('error', 'Invalid OTP Code', 'The OTP Code is invalid or already expired. Please try again.')
          }
        }).catch((err) => {
          this.$store.commit('SET_LOADING', false)
          this.otp_code = ['', '', '', '', '', '']
          renderToast('error', 'An error has occured', err)
        })
      }
    },
    openOTP () {
      const { newPW, confirmNewPW } = this
      this.hasNewPW = !((newPW === '' || newPW === null || newPW === undefined))
      this.hasConfirmNewPW = !((confirmNewPW === '' || confirmNewPW === null || confirmNewPW === undefined))

      if (!this.hasNewPW || !this.hasConfirmNewPW) {
        renderToast('error', 'Invalid Input', 'The password fields are required.')
      } else if (this.newPW !== this.confirmNewPW) {
        renderToast('error', 'Invalid Input', 'The password fields must be matched.')
      } else {
        this.showForm = false
        this.showOTP = true
      }
    },
    backToResetPassword () {
      this.showForm = true
      this.showOTP = false
      this.otp_code = ['', '', '', '', '', '']
    },
    async requestUpdatePassword () {
      const { newPW, confirmNewPW } = this

      this.hasProcessUpdate = true

      this.hasNewPW = !((newPW === '' || newPW === null || newPW === undefined))
      this.hasConfirmNewPW = !((confirmNewPW === '' || confirmNewPW === null || confirmNewPW === undefined))

      const payload = {
        email_address: this.email,
        password: newPW,
        confirm_password: confirmNewPW
      }

      if (!this.hasNewPW || !this.hasConfirmNewPW) {
        renderToast('error', 'Invalid Input', 'The password fields are required.')
      } else {
        if (newPW !== confirmNewPW) {
          renderToast('error', 'Invalid Input', 'The New and Confirm Passwords does not match.')
        } else {
          this.$store.commit('SET_LOADING', true)
          await this.$store.dispatch('doUpdatePassword', payload).then(res => {
            this.$store.commit('SET_LOADING', false)
            if (res.code === 200) {
              this.showCallback = true
              this.showOTP = false
              renderToast('success', 'Password Updated', 'The password has been updated. Kindly retry logging-in.')
            } else {
              renderToast('error', 'Error', 'Something went wrong, please try Again.')
            }
          }).catch(() => {
            renderToast('error', 'Error', 'Something went wrong, please try Again.')
          })
        }
      }
    },
    redirectToLogin () {
      this.$router.push('/login')
    },
    toggleNewPassword () {
      if (this.revealNewPassword) {
        this.revealNewPassword = false
      } else {
        this.revealNewPassword = true
      }
    },
    toggleConfirmPassword () {
      if (this.revealConfirmPassword) {
        this.revealConfirmPassword = false
      } else {
        this.revealConfirmPassword = true
      }
    }
  }
}
</script>
<style scoped>
  .login-logo {
    margin: auto;
    margin-bottom: 0;
    height: 25px;
    width: auto;
  }

  .login-inputs {
    margin-bottom: 25px;
  }

  .login-container-card {
    text-align: center;
  }

  .login-system-title {
    text-align: right;
    font-family: "Proxima Nova";
    font-size: 11.6px;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    bottom: 5px;
  }

  .branding {
    margin: auto;
    margin-top: 30px;
    height: 50px;
  }

  .branding-container {
    display: flex;
    margin-top: 0;
  }

  .reset-password-title {
    font-family: 'Baloo Extra Bold';
    font-size: 24px;
    font-weight: bolder;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #1A2791;
  }

  .reset-password-subtitle {
    font-size: 14px;
  }

  .ph-login-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    width: 100%;
    transition: .2s;
    height: 60px !important;
  }

  .ph-login-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-login-button.ph-login-reset-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
    z-index: 1;
  }

  .login-container-card .ph-back-button {
    color: #1A2791;
    display: flex;
    margin-top: 30px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .ph-back-logo {
    margin-right: auto;
  }

  .ph-back-text {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Proxima Nova Bold" !important;
    text-decoration: none;
    font-size: 14px;
  }

  .ph-action-button {
    padding-top: 20px;
  }

  .ph-action-button,
  .ph-back-button {
    padding-left: 0;
    padding-right: 0;
  }

  .ph-mail-disclaimer {
    margin-top: 70px;
    font-size: 14px;
  }

  .footer {
    position: relative;
    top: 15px;
    font-size: 12px;
    text-align: center;
    margin: auto;
  }

  .ph-textbox-container {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .login-card {
    margin: auto;
  }

  .ph-otp-input-container {
    display: flex;
    flex-direction: row;
  }

  .ph-otp-textbox {
    margin: 0 4px !important;
  }

@media only screen and (min-width: 960px) {
    .branding-container {
      height: 100%;
      margin-top: 50px;
    }

    .login-logo {
      height: 30px;
    }

    .login-system-title {
      font-size: 14px;
      position: relative;
      bottom: 5px;
    }

    .branding {
      margin-top: auto;
    }

    .footer {
      position: relative;
      top: 100px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 500px) {
    .login-card {
      margin: auto;
      text-align: center;
      width: 395px;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
    }

    .ph-otp-textbox {
      margin: 0 9px !important;
    }
  }
</style>
