import axios from 'axios'
import store from '@/store'
import { env } from '@/api/config'
import { createSignature } from '@/utils'

const http = axios.create({
  baseURL: env.get('baseURL'),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Perahub-Key': env.get('clientId')
  }
})

http.interceptors.request.use((config) => {
  const dateToday = new Date()
  const timestamp = dateToday.getTime()
  const signature = createSignature(config.data, timestamp)

  config.headers['Client-Code'] = 'usp2.0'
  config.headers.Timestamp = timestamp
  config.headers.Signature = signature

  store.commit('SET_LOADING', true)

  if (store?.state?.currUser?.token) {
    config.headers.Authorization = 'Bearer ' + store.state.currUser.token
  } else {
    delete config.headers.Authorization
  }

  return config
})

http.interceptors.response.use(
  response => {
    store.commit('SET_LOADING', false)
    return response
  },
  err => {
    store.commit('SET_LOADING', false)

    if (Object.prototype.hasOwnProperty.call(err, 'response')) {
      return err.response
    }
    return Promise.reject(err)
  }
)

export default http
