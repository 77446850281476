import api from '@/api'

export default {
  googleLogin () {
    return api.get('/usp-auth/v1/api/auth/google')
  },
  userPWLogin (payload) {
    return api.post('/usp-auth/v1/api/auth/login', payload)
  },
  verifyOTP (payload) {
    return api.post('/usp-auth/v1/api/auth/otp-validate', payload)
  },
  requestPasswordReset (payload) {
    return api.post('/usp-auth/v1/api/auth/reset-password', payload)
  },
  updatePassword (payload) {
    return api.post('/usp-auth/v1/api/auth/verify-password', payload)
  },
  retrieveUserSignature (payload) {
    return api.post('/uspdata/v1/usp-maintenance/user-signature-search', payload)
  },
  validateMachine (payload) {
    return api.get(`/uspdata/v1/usp-maintenance/device/validate?browser=${payload.browser}&key=${payload.key}`)
  }
}
